import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"

// 获取楼层
export const signDetail = () => {
  return fetchApi(URL.SIGN_DETAILS);
}

// 添加修改签到规则
export const signRule = (data) => {
  return fetchApi(URL.SIGN_RULE, data, 'POST', 'data')
}
