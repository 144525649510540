<template>
  <div>
    <a-form-model :model="form" ref="ruleForm" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 5 }">
      <a-form-model-item label="签到名称" prop="signInName">
        <a-input placeholder="请输入签到名称" v-model.trim="form.signInName"></a-input>
      </a-form-model-item>

      <a-form-model-item
        :label="index === 0 ? '获得奖励' : ' '"
        v-for="(item, index) in form.bold"
        :key="item.ruleKey"
        v-bind="index === 0 ? formItemLayout : {formItemLayout}"
        :colon="index === 0 ? true : false"
        :prop="'bold.' + index + '.ruleValue'"
        :rules="{
          required: true,
          message: '请填写ID',
          trigger: 'blur',
        }"
      >
        <span class="bold">{{ item.ruleKey }}:</span
        ><a-input v-model.trim="item.ruleValue" placeholder="请输入ID"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属项目" prop="projectIds">
        <a-select
          mode="multiple"
          v-model="form.projectIds"
          placeholder="请选择所属项目"
        >
          <a-select-option v-for="(item) in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="企业类型：" prop="enterpriseType" :span="24">
        <a-select
          :disabled='disabled'
          class="indexClass"
          placeholder="请选择"
          v-model="form.enterpriseType"
          mode="multiple"
        >
          <a-select-option v-for="item in enterpriseTypeList" :key="item.id" :value="item.id">
            {{ item.commonsValueName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="规则详情"
        prop="ruleDetail"
        :wrapperCol="{span:14}"
      >
        <div id="editer"></div>
<!--        <a-textarea v-model="form.ruleDetail" placeholder="请输入规则说明" :auto-size="{ minRows: 3, maxRows: 5 }" />-->
      </a-form-model-item>

      <div class="footer">
        <a-button key="submit" style="margin-right: 30px" type="primary" @click="save">保存 </a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { signDetail, signRule } from "@/api/checkInRuleManagement";
import { projectName } from '@/api/modularManagement'
import debounce from "lodash/debounce"
import wangEditor from "wangeditor";
import { IMG_API } from "@/config";
import { getEnterpriseTypes } from "../../../api/common";
export default {
  name: "checkInRuleManagement",
  data() {
    return {
      IMG_API: IMG_API,
      editor: null,
      // 项目列表
      projectList: [],
      // 所有企业类型
      enterpriseTypeList: [],
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 5 },
      },
      // 签到规则集合
      signRuleList: {
        bold: [
          {
            ruleKey:'day1',
            ruleValue:''
          },
          {
            ruleKey:'day2',
            ruleValue:''
          },
          {
            ruleKey:'day3',
            ruleValue:''
          },
          {
            ruleKey:'day4',
            ruleValue:''
          },
          {
            ruleKey:'day5',
            ruleValue:''
          },
          {
            ruleKey:'day6',
            ruleValue:''
          },
          {
            ruleKey:'day7',
            ruleValue:''
          },
        ],
      },
      form: {
        projectIds: undefined,
        // 企业类型
        enterpriseType: []
      },
      rules: {
        signInName: [
          { required: true, message: "请填写签到名称", trigger: "blur" },
          { required: false, pattern: new RegExp(/^[^\s]*$/, "g"), message: "禁止输入空格" },
        ],
        projectIds: [
          {required: true, message: '请选择所属项目', trigger: 'change'}
        ],
        enterpriseType: [{ required: true, message: "请选择企业类型", trigger: "blur" }],
        ruleDetail: [
          {required: true, message: '请填写积分规则详情', trigger: 'change'}
        ]
      },
    };
  },
  async created() {
    await this.getProjectList();
    await this.signDetail();
    await this.getEnterpriseTypeList();
  },
  mounted () {
    const _this = this
    const editor = new wangEditor(`#editer`)
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.ruleDetail = newHtml
    }
    editor.config.menus = [
      'fontName',
      'fontSize',
      'foreColor',
      'backColor',
      'underline',
      'italic',
      'bold',
      'justify',
      'splitLine',
      'undo',
      'redo',
      'list',
      'table',
      'image',
      'video',
    ]
    editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgShowBase64 = false
    editor.config.showLinkImg = false
    editor.config.showLinkVideo = false
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
    editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = 'file'
    editor.config.uploadVideoName = 'file'
    editor.config.uploadVideoTimeout = 60000
    editor.config.uploadImgTimeout = 60000
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function(xhr, editor, files) {
        console.log(xhr)
        console.log(editor)
        console.log(files)
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri +'" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do('insertHTML', videoHTML);
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        // insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function(xhr) {
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case 'success':
          _this.$message.success(s)
          break
        case 'info':
          _this.$message.info(s)
          break
        case 'warning':
          _this.$message.warning(s)
          break
        case 'error':
          _this.$message.error(s)
          break
        default:
          _this.$message.info(s)
          break
      }
    }
    editor.config.zIndex = 1;
    editor.config.placeholder = '请输入规则详情'
    // 创建编辑器
    editor.create()
    this.editor = editor
  },
  methods: {
    // 企业类型
    async getEnterpriseTypeList() {
      const res = await getEnterpriseTypes();
      const { code, data } = res
      if (code === '200') {
        this.enterpriseTypeList = data;
      }
    },

    // 获取项目列表
    async getProjectList() {
      const res = await projectName()
      const { code, data: { items } } = res
      if (code === '200') {
        this.projectList = items
      }
    },
    // 获取签到详情
    async signDetail() {
      const res = await signDetail();
      if (res.code === "200") {
        this.form = {
          ...res.data,
          bold: []
        };
        const bold = []
        if (this.form.signList) {
          const signRuleList = JSON.parse(this.form.signList);

          for (const key in signRuleList) {
            bold.push({
              ruleKey: key,
              ruleValue: signRuleList[key],
            });
          }
          this.form.bold = bold
          this.form.projectIds = res.data.projectIds ? res.data.projectIds.split(',') : undefined
          this.form.enterpriseType = res.data.enterpriseType || []
          this.editor.txt.html(res.data.ruleDetail)
        }
      }
    },
    // 保存
    save:debounce (function() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let obj = {
            id: this.form.id,
            signInName: this.form.signInName,
            ruleDetail: this.form.ruleDetail,
            signRuleList: this.form.bold,
            projectIds: this.form.projectIds ? this.form.projectIds.toString() : '',
            enterpriseType: this.form.enterpriseType
          };
          const res = await signRule(obj);
          if (res.code === this.$SUCCESS_CODE) {
            this.$message.success("编辑成功");
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 500)
  },
};
</script>

<style>
.bold {
  font-weight: bold;
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
